import { useEffect, useState } from 'react';
import { last, isUndefined, throttle, get } from 'utils/lodashExtracts';


export const useAnchorEffect = (
  anchorLinksContainerRef,
  anchorLinksRefs,
  anchorClassName,
  magicOffset = 0) => {
  const [scrollStatus, setScrollStatus] = useState({
    scrollingUp: false,
    hoveredAnchorId: undefined
  });

  useEffect(() => {
    let prevScroll = window.pageYOffset;

    function scrollHandler() {
      const currentScroll = window.pageYOffset;
      const goingUp = prevScroll > currentScroll;

      // Magic offset here because global navigation bar and this bar, take some amount of space
      const anchorList = Array.from(document.getElementsByClassName(anchorClassName));
      const alreadyPassedAnchors = anchorList.filter((anchor) => anchor.getBoundingClientRect().top < magicOffset);
      const currentlyHoveredAnchor = last(alreadyPassedAnchors);
      const currentlyHoveredAnchorId = isUndefined(currentlyHoveredAnchor) ? undefined : currentlyHoveredAnchor.id;
      setScrollStatus({
        scrollingUp: goingUp,
        hoveredAnchorId: currentlyHoveredAnchorId
      })
      prevScroll = currentScroll;
    }
    const throttledHandler = throttle(scrollHandler, 15);
    window.addEventListener('scroll', throttledHandler);
    return () => {
      window.removeEventListener('scroll', throttledHandler);
    };
  }, []);

  useEffect(() => {
    if (!isUndefined(scrollStatus.hoveredAnchorId)) {
      const container = anchorLinksContainerRef.current;
      const anchorLinkElement = anchorLinksRefs.current[scrollStatus.hoveredAnchorId];
      // center the element in the container
      container.scrollLeft = anchorLinkElement.offsetLeft - (container.offsetWidth / 2) + (anchorLinkElement.offsetWidth / 2);
    }
  }, [scrollStatus.hoveredAnchorId]);


  return [scrollStatus, setScrollStatus]
}

export const scrollToAnchor = (id, additionalOffset = 20) => {

  const scrollTopGetter = document.getElementById(id).getBoundingClientRect().top + window.pageYOffset + additionalOffset;
  window.scrollTo(0, scrollTopGetter);
}