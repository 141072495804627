import React from "react";
import cx from "classnames";
import Container from "react-bootstrap/Container";
import { scrollToAnchor } from "components/common/CharlesLayout/useAnchorEffect";

import "./styles.sass";

type Anchor = {
  id: string;
  label: string;
  hide?: boolean
};

type Props = {
  anchors: Anchor[];
  anchorLinksRefs: any;
  anchorLinksContainerRef: any;
  scrollingUp: boolean;
  hoveredAnchorId: string;
  scrollOffset: number;
};

const AnchorMenu = ({
  anchors,
  anchorLinksRefs,
  anchorLinksContainerRef,
  scrollingUp,
  hoveredAnchorId,
  scrollOffset
}: Props) => (
  <div
    className={cx("anchor-link-wrapper anchor-bar-sticky", {
      "anchor-bar-going-up": scrollingUp,
    })}
  >
    <Container className="anchor-link-container" ref={anchorLinksContainerRef}>
      {anchors.map((anchor) => anchor.hide ? null : (
        <div
          key={`anchor-key-${anchor.id}`}
          id={`to-${anchor.id}`}
          className={cx("anchor-link", {
            highlighted: hoveredAnchorId === anchor.id,
          })}
          onClick={() => scrollToAnchor(anchor.id, scrollOffset)}
          ref={(element) => (anchorLinksRefs.current[anchor.id] = element)}
        >
          {anchor.label}
        </div>
      ))}
    </Container>
  </div>
);
export default AnchorMenu;
