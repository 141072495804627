import React, { useState } from 'react';
import './styles.sass';
import { homeTestimonies } from '../common/data/testimonies';
import TestimonyCarousel, {textOrBeforeAfterTestimonyRenderer } from 'components/common/TestimonyCarousel';
import cx from 'classnames';

const TestimonySection = ({ className }: { className?: string }) => (
  <div className={cx('landing-testimony-section', className)}>
    <div className="landing-testimony-text">
      <h2 className="landing-testimony-section-h2">
        Nos patients témoignent
      </h2>
    </div>
    <TestimonyCarousel testimonies={homeTestimonies} renderTestimony={textOrBeforeAfterTestimonyRenderer} />
  </div>
);

export default TestimonySection;
