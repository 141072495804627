import React from 'react';
import HowItWorks from 'components/HowItWorks';

// import Image from 'components/image'@
// import SEO from 'components/seo'
/* {
  <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
} */

const HowPage = () => <HowItWorks />;

export default HowPage;
